import axios from 'axios'

axios.defaults.baseURL = 'http://101.132.122.204:60111/api/'

// 请求拦截器
axios.interceptors.request.use(function(config) {

    return config
})

// 响应拦截器
axios.interceptors.response.use(function(response) {
    if (response.status === 200) {
        return Promise.resolve(response.data)
    } else {
        return Promise.reject(response.data)
    }
}, function(err) {
    return Promise.reject(err.response)
})

//返回一个Promise(发送post请求)
export function POST(url, param) {
    return new Promise((resolve, reject) => {
        axios.post(url, param)
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

// 返回一个Promise(发送get请求)
export function GET(url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}