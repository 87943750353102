import { POST, GET } from './index'

// 提交留言
export function AddLeavingInfo(params) {
    return POST('PD_Portal/AddLeavingInfo', params)
}

// 首页信息
export function ShowHomeInfo(params) {
    return GET('PD_Portal/ShowHomePortalInfo')
}
// 模块详情信息
export function ShowPortalInfo(params) {
    return GET(`PD_Portal/ShowPortalInfo?${params}`)
}